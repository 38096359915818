import React from "react";
import Back from "../common/back/Back";
import "../css/career.css";
import Faq from "./Faq";
import CareerInfo from "./CareerInfo";

const Career = () => {
  return (
    <>
      <Back title="CAREER" />
      <CareerInfo />
      <Faq />
    </>
  );
};

export default Career;
