import React from "react"
import Hero from "./hero/Hero"
import Mars from "./mars/Mars"
import Testimonal from "./testimonal/Testimonal"
import WbsHome from "./whistleblow/WbsHome"

const Home = () => {
  return (
    <>
      <Hero />
      <Mars />
      {/* <Testimonal /> */}
      <WbsHome />
    </>
  )
}

export default Home
