import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./components/about/About";
import Service from "./components/services/Services";
import Career from "./components/career/CareerPage";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import MediaPage from "./components/mediaroom/MediaPage";
import BusinessPage from "./components/business/BusinessPage";
// import TestiDcare from "./components/home/testimonal/dcare/TestiDcare";
// import TestiPoliklinik from "./components/home/testimonal/poliklinik/TestiPoliklinik";
// import TestiUTSchool from "./components/home/testimonal/utschool/TestiUTschool";
// import TranslationEng from "./Translation/TranslationEng";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/business" component={BusinessPage} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/media" component={MediaPage} />
          <Route exact path="/contact" component={Contact} />
          {/* <Route exact path="/translation" component={TranslationEng} /> */}
          {/* <Route exact path="/testipoliklinik" component={TestiPoliklinik} />
          <Route exact path="/testidcare" component={TestiDcare} />
          <Route exact path="/testiutschool" component={TestiUTSchool} /> */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
