import React from "react";
import { mars } from "../../../assets";
import "./mars.css"
import Heading from "../../common/heading/Heading";

const Mars = () => {
  return (
    <div>
      <Heading subtitle="Mars Yayasan Karya Bakti UT" title="MARS YKBUT" />
      <div className="mars">
        <video autoPlay controls src={mars} />
      </div>
    </div>
  );
};

export default Mars;
