import React from "react";
// import { blog } from "../../../dummydata";
import Logo from "../../../assets/image/logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div
          className="padding"
          style={{
            textAlign: "center",
            padding: "0px 0",
            backgroundColor: "white",
            display: "grid",
          }}
        >
          <div className="box logo">
            <div className="logo">
              <a href="/" className="homeLink">
                <img
                  src={Logo}
                  alt="LOGO YKBUT"
                  className="footer"
                  style={{ width: "auto", height: "90px" }}
                />
              </a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/ykbutofficial/"
                className="nav-link p-0 text-muted mx-2"
              >
                <i className="fab fa-instagram icon"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/@ykbutofficial/"
                className="nav-link p-0 text-muted mx-2"
              >
                <i className="fab fa-youtube icon"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/yayasan-karya-bakti-ut/"
                className="nav-link p-0 text-muted mx-2"
              >
                <i className="fab fa-linkedin icon"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2022 All rights reserved | YAYASAN KARYA BAKTI UT{" "}
          <i className="fa fa-heart" style={{ color: "#0278AE" }}></i>
        </p>
      </div>
    </>
  );
};

export default Footer;
