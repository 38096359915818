import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Back from "../common/back/Back";
import "../css/Contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ywf5g8g",
        "template_y66ab4o",
        form.current,
        "v3srM6HcdqZYLnh0G"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message send");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <Back title="CONTACT US" />
      <section className="contacts padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <div class="mapouter" style={{ height: "100%", padding: "40px" }}>
              <div class="gmap_canvas" style={{ height: "100%" }}>
                <iframe
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Jl.%20Raya%20Bekasi%20No.KM.%2022,%20Cakung%20Bar.,%20Kec.%20Cakung,%20Kota%20Jakarta%20Timur,%20Daerah%20Khusus%20Ibukota%20Jakarta%2013910&t=&z=17&ie=UTF8&iwloc=&output=embed"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="right row">
            <h1>CONTACT US</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className="items">
              <div className="box">
                <h4>WORKING HOURS :</h4>
                <p style={{ color: "#000B49" }}>
                  Monday - Thursday : 07.30 to 16.30 WIB
                </p>
                <p style={{ color: "#000B49" }}>
                  Friday : 07.30 to 17.00 WIB
                </p>
                <p style={{ marginTop: 0, color: "#A10035" }}>
                  Saturday - Sunday : "Closed"
                </p>
              </div>
              <div className="box">
                <h4>LOCATION :</h4>
                <p>
                  Jl. Raya Bekasi No.KM. 22, Cakung Bar. / Kecamatan Cakung,
                  Kota Jakarta Timur/ DKI Jakarta 13910
                </p>
              </div>
              <div className="box">
                <h4>EMAIL :</h4>
                <p>
                  <a
                    href="mailto:official@ykbut.co.id"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                  >
                    {" "}
                    official@ykbut.co.id
                  </a>
                </p>
                {/* <p>
                  Secretary 1 of YKBUT :
                  <a
                    href="mailto:siti.dewi@ykbut.co.id"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                  >
                    {" "}
                    siti.dewi@ykbut.co.id
                  </a>
                </p> */}
              </div>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="flexSB">
                <input type="text" name="user_name" placeholder="Name" />
                <input type="email" name="user_email" placeholder="Email" />
              </div>
              <input type="text" placeholder="Subject" />
              <textarea
                name="message"
                cols="30"
                rows="10"
                placeholder="Create message here..."
              ></textarea>
              <button
                className="primary-btn"
                type="submit"
                value="SEND MESSAGE"
              >
                SEND MESSAGE
              </button>
            </form>

            <h3>FOLLOW US</h3>
            <span>INSTAGRAM | YOUTUBE</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
