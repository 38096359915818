import React from "react";
import { useLocation } from "react-router-dom";

const Back = ({ title }) => {
  const location = useLocation();

  return (
    <>
      <section className="back">
        <h2>Beranda / {location.pathname.split("/")[1]}</h2>
        <h1 style={{ width: "100%", padding: "45px 0px", fontSize: "70px" }}>{title}</h1>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Back;
