import React from "react";
import { logowbs, mars } from "../../../assets";
import Heading from "../../common/heading/Heading";
import "./wbs.css";
import { logoall } from "../../../assets";

const WbsHome = () => {
  return (
    <>
      <section className="wbs padding">
        <div className="container">
          <Heading subtitle="Whistle Blowing System" title="Whistle Blowing" />
          <div className="contex" style={{ marginTop: "60px" }}>
            <div className="logowbs">
              <img src={logowbs} alt="" style={{marginRight: "55px"}}/>
            </div>
            <div className="context">
              <p>
                Whistle Blowing System adalah mekanisme bagi
                yayasan/koperasi/perusahaan dalam pelaporan atas dugaan
                pelanggaran atau kecurangan yang berindikasi merugikan bagi
                Perusahaan atau hal-hal lain yang melanggar kode etik dan
                peraturan perundang-undangan.
              </p>
              {/* <p>
                Pelapor dapat menyampaikan laporan dugaan pelanggaran kepada
                TKPP melalui saluran yang telah disediakan bawah ini:
              </p> */}
              <div className="contactwbs">
                {/* <p>
                  <i class="fas fa-envelope" style={{ paddingRight: "20px" }} />
                  Email :{" "}
                  <a
                    href="mailto: gcg-ykbutgroup@ykbut.co.id"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                  >
                    {" "}
                    gcg-ykbutgroup@ykbut.co.id
                  </a>
                </p> */}
                {/* <p>
                  <i class="fab fa-whatsapp" style={{ paddingRight: "20px" }} />
                  SMS/WA :
                  <a
                    href="https://wa.me/+6281514936111"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                    target="blank"
                  >
                    {" "}
                    Endang Tri Handajani,
                  </a>
                  <a
                    href="https://wa.me/+628121134334"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                    target="blank"
                  >
                    {" "}
                    Dianwahyu Sri P,
                  </a>
                  <a
                    href="https://wa.me/+6285921581230"
                    style={{ fontSize: "17px", marginTop: "0px" }}
                    target="blank"
                  >
                    {" "}
                    Heru Kurniawan C
                  </a>
                </p> */}
              </div>
              <div>
                <a href="PedomanWhistleBlowerYKBUTR12024.pdf" target="_blank">
                  <button className="primary-btn">Read More</button>
                </a>
                <a
                  href="PedomanWhistleBlowerYKBUTR12024.pdf"
                  download="Pedoman Whisle Blower YKBUT R1 2024.pdf"
                >
                  <button className="primary-btn">Download PDF</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <img style={{ width: "100%" }} src={logoall} />
      </div>
    </>
  );
};

export default WbsHome;
