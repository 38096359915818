import React from "react";
import Back from "../common/back/Back";
import BusinessCard from "./BusinessCard";
import "../css/Business.css";

const BusinessPage = () => {
  return (
    <div className="">
      <Back title="BUSINESS UNIT" />
      <section className="team padding">
        <div
          className="container grid"
          style={{
            gridTemplateColumns: "auto auto",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <BusinessCard />
        </div>
      </section>
    </div>
  );
};

export default BusinessPage;