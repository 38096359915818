import React from "react";
import { barcode_magazine, cover_magazine, magazine1 } from "../../assets";
import Heading from "../common/heading/Heading";
import "../css/Magazine.css";

const Magazine = () => {
  return (
    <>
      <section className="magazine padding">
        <div className="container">
          <Heading subtitle="Magazine YKBUT" title="Magazine" />
          <div className="contex" style={{ marginTop: "60px" }}>
            <div className="covermagazine">
              <img src={cover_magazine} />
            </div>
            <div className="context" style={{ paddingLeft: "60px" }}>
              <p>
                Magazine YKBUT merupakan wadah yang penuh dengan kreativitas,
                inspirasi, dan pengetahuan baru yang akan memikat Anda. Kami
                dengan bangga mempersembahkan platform ini sebagai tempat bagi
                para pembaca untuk menjelajahi dunia yang luas dan beragam,
                mengeksplorasi topik-topik menarik, dan mendapatkan wawasan yang
                mendalam. Lihat lebih lanjut MAGAZINE YKBUT dengan klik atau
                sqan QR dibawah ini.
              </p>
              <div style={{ width: "150px", height: "150px" }}>
                <img src={barcode_magazine} />
                <a href="https://magazine.ykbut.co.id/books" target="_blank">
                  <button className="primary-btn">Read More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Magazine;
