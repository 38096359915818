import { penanamanPohon, pancadaya, senam, donor_darah, ykbut2, bukber2024, mangrove, mangrove1, cookingpapa, klinik } from "./assets";

// HOME
export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/ios/512/city-buildings.png",
    title: "WBAWI",
    desc: "Yayasan Karya Bakti United Tractors (YKBUT) didirikan oleh PT United Tractors Tbk (UT) pada 27 Februari 2008.",
  },
  {
    id: 1,
    cover:
      "https://img.icons8.com/fluency-systems-regular/512/purposeful-man.png",
    title: "VISION",
    desc: "Menjadi Yayasan yang Terbaik di Tingkat Nasional dalam Memberikan Nilai Tambah & Layanan bagi seluruh Stakeholder-nya.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/fluency-systems-regular/512/rocket.png",
    title: "MISSION",
    desc: "Menciptakan Sumber Daya Manusia yang Berkualitas, Berdaya Saing Tinggi, Profesional, dan Berkarakter Mandiri, Menciptakan Jaringan yang Luas dengan Industri Didalam dan Diluar Negeri, Mengelola Yayasan secara Bersih, Bertanggung Jawab dan Menomorsatukan Integritas, Menyediakan Layanan Kesehatan yang Berkualitas & Terjangkau.",
  },
];


export const online = [
  {
    cover: "./images/Logo/3.png",
    hoverCover: "./images/Logo/3.png",
    courseName: "PT Mitra Bakti UT",
    link: "http://erp.mitrabaktiut.co.id/jobs",
  },
  {
    cover: "./images/Logo/4.png",
    hoverCover: "./images/Logo/4.png",
    courseName: "PT Global Service Indonesia",
    link: "https://web.globalservice.co.id/index.php/sample-page/",
  },
  {
    cover: "./images/Logo/6.png",
    hoverCover: "./images/Logo/6.png",
    courseName: "PT Kanitra Mitra Jayautama",
    link: "https://hipe.kamaju.co.id/jobs",
  },
  {
    cover: "./images/Logo/5.png",
    hoverCover: "./images/Logo/5.png",
    courseName: "PT Dian Pandu Pratama",
    link: "https://dpp.tbdigitalindo.co.id/jobs",
  },
  {
    cover: "./images/Logo/2.png",
    hoverCover: "./images/Logo/2.png",
    courseName: "PT Karsa Mulia Sejahtera",
    link: "",
  },
];

// Services
export const services = [
  {
    cover: "./images/business/utschool.png",
    name: "UT SCHOOL",
    work: "Lembaga Pendidikan Keterampilan Mekanik, Operator Alat-alat Berat serta Konstruksi Terbaik di Dunia",
    url: "https://www.utschool.sch.id/",
    linkIg: "https://www.instagram.com/utschoolinsani/",
    youtube: "https://www.youtube.com/c/UTSchoolinsani",
  },
  {
    cover: "./images/business/dcare.png",
    name: "UT D'CARE",
    work: "Sarana Pengasuhan Balita, Konsultasi gizi dan tumbuh kembang anak, Parenting",
    url: "https://dcareut.com/login",
    linkIg: "https://www.instagram.com/dcare_ut/?hl=id",
    youtube: "https://www.youtube.com/channel/UCDl_MyAsxJb2DIFLK8n1hng",
  },
  {
    cover: "./images/business/poli.png",
    name: "KLINIK PRATAMA UNITED TRACTORS",
    work: "Penyedia Layanan kesehatan AHEMCE Group dan Poliklinik Satelit BPJS",
    url: "https://poliklinikut.com/",
    linkIg: "https://www.instagram.com/poliklinikpratama.ut/?hl=id",
    youtube: "https://www.youtube.com/",
  },
  {
    cover: "./images/business/ylc.png",
    name: "YKBUT LEARNING CENTER",
    work: "Penyediaan Layanan  Knowledge Management,People Development, People Certification & People Solution",
    url: "https://ylcku.com/",
    linkIg: "https://www.instagram.com/ylcofficial/?hl=id/",
    youtube: "https://www.youtube.com/channel/UC23eQZlbI_BavTiKZcgEcRg",
  },
];
// Business
export const business = [
  {
    cover: "./images/business/mbut.png",
    name: "PT MITRA BAKTI UT",
    work: "Perusahaan building management yang diakui keberadaannya di Indonesia, peduli akan lingkungan, serta dapat dipercaya.",
    url: "https://www.mitrabaktiut.co.id/",
    linkIg: "https://www.instagram.com/mitrabaktiutofficial/",
    youtube: "https://www.youtube.com/channel/UCh9_frZcWg0hWb72zjOfCoQ",
  },
  {
    cover: "./images/business/gsi.png",
    name: "PT GLOBAL SERVICE INDONESIA",
    work: "Penyediaan jasa SDM bidang Administrasi dan IT berbagai perusahaan di Astra Group",
    url: "http://globalservice.co.id",
    linkIg: "https://www.instagram.com/globalserviceid/",
    youtube: "https://www.youtube.com/channel/UCwi6u2U9duiBc6huIPP_QFQ",
  },
  {
    cover: "./images/business/kamaju.png",
    name: "PT KANITRA MITRA JAYAUTAMA",
    work: "Penyedia Jasa Mekanik dan Operator Alat-Alat Berat bagi perusahaan-perusahaan di Astra Group ",
    url: "https://www.kamaju.co.id/",
    linkIg: "https://www.instagram.com/kamajuofficial/",
    youtube: "https://www.youtube.com/c/kamajuofficial",
  },
  {
    cover: "./images/business/dpp.png",
    name: "PT DIAN PANDU PRATAMA",
    work: "sub-contractor di bidang jasa fabrikasi dan perakitan alat angkut",
    url: "https://www.dpp.co.id/",
    linkIg: "https://www.instagram.com/officialdpp_/",
    youtube: "https://www.youtube.com/channel/UCWU6HGrkfLTX8j8ulmI8NIw",
  },
  {
    cover: "./images/business/kms.png",
    name: "PT KARSA MULIA SEJAHTERA",
    work: "Penyedia Tenaga Kerja Profesional yang berkompetensi tinggi dibidang Disassy , Washing , Warehouse Man dan Administration Remanufacturing di lingkungan AHEMCE .",
    url: "https://www.karsamulia.co.id/",
    linkIg: "https://www.karsamulia.co.id",
    youtube: "https://www.youtube.com/@karsamuliasejahtera6718",
  },
  {
    cover: "./images/business/kopkar.png",
    name: "KOPERASI KARYAWAN YKBUT",
    work: "Badan Usaha yang dimiliki serta dikelola oleh anggotanya yang merupakan karyawan YKBUT dan Entitasnya.",
    url: "https://kopkarkita.ykbut.co.id/",
    linkIg: "https://www.instagram.com/ykbutofficial/",
    youtube: "https://www.youtube.com/channel/UCOsZmiEPqgyOwOPDRJYUJzg",
  },
  {
    cover: "./images/business/lsp-abi.png",
    name: "LSP ABI",
    work: "Lembaga pendukung Badan Nasional Sertifikasi Profesi (BNSP) sebagai lembaga sertifikasi profesi dibidang alat berat kelas dunia.",
    url: "https://www.lsp-abi.org/",
    linkIg: "https://www.instagram.com/lspabi/",
    youtube: "https://www.youtube.com/channel/UC17R4apJPOZmNkcB-ylfiyw",
  },
  {
    cover: "./images/business/lsp-alkindo.png",
    name: "LSP ALKINDO",
    work: "Lembaga pendukung Badan Nasional Sertifikasi Profesi (BNSP) sebagai lembaga sertifikasi profesi dibidang Konstruksi.",
    url: "https://lspalkindo.co.id/",
    linkIg: "https://www.instagram.com/lspalkindo/",
    youtube: "https://www.youtube.com",
  },
];

// CAREER FAQ
export const faq = [
  {
    title:
      "Bagaimana saya mendapatkan kesempatan untuk mengisi posisi yang sedang dibutuhkan di YKBUT & KANITRA Goup? ",
    desc: "Terus mengembangkan diri, perbanyak skill dan pengalaman organisasimu.",
  },
  {
    title: "Dimana saya bisa mendapatkan informasi seputar rekrutmen YKBUT & KANITRA Goup?",
    desc: "Kamu dapat melihat informasi seputar rekrutmen YKBUT pada akun social media resmi kami di @ykbutoffcial pada Instagram serta pada akun social media resmi rekrutmen @career.ykbut untuk informasi seputar rekrutmen Yayasan Karya Bakti United Tractors,informasi karir, penipuan, dsb nya.",
  },
  {
    title:
      "Berkas apa saja yang perlu disiapkan untuk apply lamaran kerja di YKBUT & KANITRA Goup?",
    desc: "Silahkan mempersiapkan biodata dan dokumen lainnya sesuai dengan persyaratan yang tertera didalam iklan lowongan Instagram dan website resmi YKBUT.",
  },
  {
    title: " Apa saja tahapan seleksi kerja YKBUT?  ",
    desc: "Tahapan seleksi kerja YKBUT dimulai dengan seleksi administrasi, psikotes offline/online, interview HC, wawancara user dan Medical Check Up (MCU).",
  },
  {
    title:
      "Bagaimana jika latar belakang pendidikan saya berbeda dengan kualifikasi pendidikan dalam lowongan pekerjaan di YKBUT & KANITRA Goup? ",
    desc: "Kami mencari kandidat pelamar kerja sesuai dengan kualifikasi pendidikan yang tercantum di dalam iklan lamaran pekerjaan.",
  },
  {
    title:
      "Kemanakah saya bisa hubungi apabila ada kendala dalam apply lamaran pekerjaan? ",
    desc: "Silahkan menghubungi melalui website chat kami. ",
  },
  {
    title: "Apakah saya boleh apply lebih dari satu posisi?",
    desc: "Silahkan kamu melamar pekerjaan sesuai dengan kemampuan yang paling kamu kuasai.",
  },
  {
    title:
      "Setelah melakukan apply, bagaimana saya bisa mengetahui kelanjutan prosesnya? ",
    desc: "Kamu bisa melakukan pengecekan secara berkala melalui masing-masing website yang sudah terintegritas dengan website kami.",
  },
  {
    title: "Apa proses selanjutnya setelah apply lamaran kerja di YKBUT & KANITRA Goup? ",
    desc: "Proses selanjutnya ialah seleksi administrasi. Kandidat yang lolos seleksi administrasi, akan kami hubungi melalui telepon maupun email. ",
  },
  {
    title:
      "Berapa lamakah waktu seleksi dalam satu periode seleksi pekerjaan di YKBUT & KANITRA Goup?",
    desc: "Satu periode seleksi memakan waktu kurang lebih 14 hari kerja sejak ditutupnya batas akhir lowongan kerja tersebut.",
  },
  {
    title: "Dimanakah hasil seleksi akan diumumkan?",
    desc: "Hasil seleksi yang lolos akan diumumkan melalui media sosial Instagram dan atau email dari kami.",
  },
  {
    title:
      "Apakah kandidat harus membayar sejumlah uang selama proses recruitment?",
    desc: "Kami tidak pernah memungut biaya apapun dalam setiap proses rekrutmen karyawan. Jika ada oknum atau apapun lowongan yang mengatasnamakan YKBUT dengan modus pemungutan biaya, mohon abaikan dan informasikan kepada kami agar tidak terjadi halhal yang tidak diinginkan.",
  },
  {
    title:
      "Jika saya dinyatakan belum lolos di periode seleksi ini, apakah saya masih dapat melamar kembali pada periode seleksi selanjutnya? ",
    desc: "Kamu dapat melamar kembali di periode seleksi selanjutnya dalam jangka waktu 6 bulan setelah dinyatakan tidak lolos pada periode ini. ",
  },
  {
    title: "Bagaimana peraturan kerja di YKBUT selama masa pandemik COVID-19?",
    desc: "Kami menerapkan sistem kerja Work from Office (WFO) dan Work from Home (WFH) menyesuaikan dengan operasional perusahaan.",
  },
];

// HOME TESTIMONIAL
export const testimonal = [
  {
    id: 1,
    name: "NUR K.A HEMAS",
    post: "CORP.HC & CORPU ASSOCIATE PT UNITED TRACTORS",
    desc: "UT Group menciptakan lingkungan kerja yang mendukung keberagaman di dalamnya. Peluang untuk berkembang, berinovasi dan...",
    cover: "./images/testo/testingdcare.jpg",
    link: "/testidcare",
  },
  {
    id: 2,
    name: "JOHANES KEVIN",
    post: "GM OPERATION SYSTEM & PEOPLE DEVELOP PT. BUMA",
    desc: "Menurut saya secara pengetahuan mekanik UT School kami nilai baik, mereka memliki pengetahuan yang baik, baik secara technical...",
    cover: "./images/testo/testinguts1.jpg",
    link: "/testiutschool",
  },
  {
    id: 3,
    name: "LIE GUNADI",
    post: "KOMISARIS PT GLOBAL SERVICE INDONESIA  ",
    desc: "Poliklinik PT. United Tractors jauh lebih baik dibandingkan yang dulu. Perbedaan yang signifikan sudah pasti dari...",
    cover: "./images/testo/testingpoli1.jpg",
    link: "/testipoliklinik",
  },
];

// MEDIA IMAGE GALLERY
export const GalleryData = [
  {
    titile: "Building",
    image: ykbut2,
    description: "Loby Gedung YKBUT",
  },
  {
    titile: "Building",
    image: pancadaya,
    description: "Ruang Pancadaya",
  },
  {
    titile: "Galeri CSR",
    image: penanamanPohon,
    description: "Seremoni Penanaman 15.000 pohon",
  },
  {
    titile: "Galeri CSR",
    image: klinik,
    description: "",
  },
  {
    titile: "Event",
    image: senam,
    description: "Lomba Senam HUT YKBUT",
  },
  {
    titile: "Galeri CSR",
    image: donor_darah,
    description: "",
  },
  {
    titile: "Event",
    image: bukber2024,
    description: "",
  },
  {
    titile: "Galeri CSR",
    image: mangrove,
    description: "",
  },
  {
    titile: "Galeri CSR",
    image: mangrove1,
    description: "",
  },
  {
    titile: "Event",
    image: cookingpapa,
    description: "",
  },
];
