import React from "react";
import Heading from "../common/heading/Heading";
import "../css/About.css";
import { homeAbout } from "../../dummydata";
import { teams, hero, culture } from "../../assets";
import ReactPlayer from "react-player";

const AboutCard = () => {
  return (
    <>
      <section className="aboutHome">
        <div className="container flexSB">
          <div className="left row" style={{ padding: "100px 0 0" }}>
            <img src={hero} alt="" />
          </div>

          <div className="right row">
            <div style={{ fontSize: "30px" }}>
              <Heading subtitle="ALL ABOUT YKBUT" />
            </div>

            <div className="items">
              {homeAbout.map((val) => {
                return (
                  <div className="item flexSB">
                    <div className="img">
                      <img src={val.cover} alt="" />
                    </div>
                    <div className="text">
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <History /> */}
      <Heading subtitle="Yayasan Karya Bakti UT" title="PUBLIC CONTRIBUTION" />
      <div style={{display: "flex", alignItems:"center", justifyContent:"center"}}>
        <ReactPlayer controls url="https://youtu.be/evyihCmJSsM" />
      </div>
      <div>
        <Heading
          subtitle="Character & Culture"
          title="PANCADAYA & PEDULI INSANI"
        />
        <img
          src={culture}
          alt="culture-ykbut"
          className="culture"
          style={{
            display: "block",
            width: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Heading
          subtitle={""}
          title="ORGANIZATION STRUCTURE"
        />
        <img src={teams} alt="struktur-organisasi" className="teams" />
      </div>
    </>
  );
};

export default AboutCard;
