import React from "react"
import Back from "../common/back/Back"
import ServiceCard from "./ServiceCard"
import "../css/Services.css"
import "../css/About.css"

const Service = () => {
  return (
    <>
      <Back title='SERVICE' />
      <section className='team padding'>
        <div className='container grid' style={{gridTemplateColumns: "auto auto"}}>
          <ServiceCard />
        </div>
      </section>
      {/* <Awrapper /> */}
    </>
  )
}

export default Service
